import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Avatar, Typography } from "@mui/material";

import { getObjLength } from "../helpers/utils";
import { PER_PAGE } from "../helpers/constants";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";

const AgentsProfileList = ({
  agentList = [],
  selectedAgentsProfile = () => {},
  backToCreateAccount = () => {},
}) => {
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const { agentCreateAccDetails, agentProfileListData, agentProfilePayload } =
    useSelector((state) => state?.brokerAgents);

  const handlePageChange = (e, p) => {
    setPage(p);
    dispatch(
      brokerAgentsAction.getAgentProfileListData({
        url: `broker/get-agent-profile`,
        data: { ...agentProfilePayload, page: p },
      })
    );
  };

  useEffect(() => {
    console.log(agentProfileListData);

    if (getObjLength(agentProfileListData)) {
      if (agentProfileListData?.success) {
        if (pageCount === 0)
          setPageCount(Math.ceil(agentProfileListData?.count / PER_PAGE));
      }
    }
  }, [agentProfileListData]);
  return (
    <>
      <Box className="accountBoxWrapper findPropertyBoxWrapper">
        <Typography align="center" variant="h3">
          Agent's Profile List
        </Typography>
        <Typography align="center" variant="body1">
          Please select your relevant profile from the Agent's Profile list.
        </Typography>
        <Grid container spacing={3} alignItems="center" className="fw-700">
          <Grid
            item
            sm={6}
            md={3}
            lg={3}
            className="d-flex justify-content-start align-items-center text-center"
          >
            <span className="px-4">Agent</span>
          </Grid>
          <Grid item sm={6} md={3} lg={3} textAlign={"left"}>
            Business name
          </Grid>
          <Grid item sm={6} md={3} lg={3}>
            Phone number
          </Grid>
          <Grid item sm={6} md={3} lg={3}></Grid>
        </Grid>
        {agentList?.map((agent, index) => (
          <Box sx={{ flexGrow: 1 }} mt={2} className="propertyList px-1">
            <Grid container spacing={3} alignItems="center">
              <Grid
                item
                sm={6}
                md={3}
                lg={3}
                className="d-flex justify-content-start align-items-center"
              >
                <span className="company-logo">
                  <Avatar
                    src={`data:image/jpeg;base64,${agent?.profile_photo_src}`}
                    alt={agent?.full_name}
                    sx={{ width: 50, height: 50 }} // Adjust size as needed
                  />
                </span>
                <span className="px-3">{agent?.full_name || "-"}</span>
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {agent?.business_name || "-"}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                {agent?.phone_number || "-"}
              </Grid>
              <Grid item sm={6} md={3} lg={3}>
                <Button
                  className="popup_Btn text-center success_popup_btn"
                  onClick={() => {
                    selectedAgentsProfile(agent);
                  }}
                >
                  Select
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
        {getObjLength(agentCreateAccDetails) &&
          !agentCreateAccDetails?.success && (
            <p className="fw-500 terms_error w-100 text-danger text-center my-2">
              {agentCreateAccDetails?.data?.message}
            </p>
          )}
        {/* <Box
          className="mt-5"
          id="pagination-container"
        >
          {pageCount > 1 && (
            <CustomPagination
              count={pageCount}
              page={page}
              handlePageChange={handlePageChange}
            />
          )}
        </Box> */}
        <div className="text-center stepBtn">
          <Button
            onClick={backToCreateAccount}
            className="popup_Btn other_popup_btn my-3"
            color="primary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        </div>
      </Box>
    </>
  );
};

export default AgentsProfileList;
