import axios from "axios";
import { toast } from "react-toastify";

import {
  decryptPayload,
  getObjLength,
  windowsAndroidNativeMethods,
} from "../components/helpers/utils";
import { BASE_URL, ROLES } from "../components/helpers/constants";

export const getAPIPayload = async (data) => {
  console.log("payload", data);
  if (getObjLength(data)) {
    let passingData = data.data === undefined ? {} : data.data;
    let finalURL = data.url;
    let preURL = data.url.split("?")?.[0];
    let postURL = data.url.split("?")?.[1];
    let device = decryptPayload(localStorage.getItem("isMobile"));

    // to identify logged in devices
    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.customer) ||
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.professional
      ) ||
      decryptPayload(localStorage.getItem("path"))?.includes("create-account")
    ) {
      if (device) {
        finalURL = `${preURL}?device_type=${device}${
          postURL ? "&" + postURL : ""
        }`;
      } else {
        finalURL = `${preURL}?device_type=web${postURL ? "&" + postURL : ""}`;
      }
    }

    return await getAPICall(
      finalURL,
      passingData,
      data.token,
      data?.brokertoken
    );
  } else {
    return "";
  }
};

export const getAPICall = async (url, reqbody, token, brokertoken) => {
  return await axios
    .get(`${BASE_URL}${url}`, {
      headers: {
        authorization: token
          ? `Bearer ${localStorage.getItem("authToken")}`
          : brokertoken
          ? `Bearer ${localStorage.getItem("agentauthToken")}`
          : null,
      },
    })
    .then(function (response) {
      console.log("in GetPayload gettRequest...", response.data);
      return response.data;
    })
    .catch(function (error) {
      console.error("Error in GetAPIPayload gettRequest", error);
      let data = {
        success: false,
        error: error.message,
        data: error.response?.data || {
          success: false,
          message: error.message,
        },
      };
      if (error.response?.status === 401) {
        const path = window.location.pathname;
        console.log("pathname post ==>", path);
        if (path !== "/login" && !path?.includes("investors")) {
          localStorage.setItem("previousPath", path);
          localStorage.setItem("previousUserID", localStorage.getItem("uID"));
        }
        // localStorage.getItem("uID");
        localStorage.removeItem("authToken");
        localStorage.removeItem("authorised");
        toast.error("Your token is expired or invalid. Please Login again.");

        // logout native method call for mobile app
        if (
          window?.webkit &&
          window?.webkit?.messageHandlers &&
          window?.webkit?.messageHandlers?.invokeNativeLogout
        ) {
          console.log("Logout Native App got called...");
          window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
        }
        // call Android App Logout method
        windowsAndroidNativeMethods("logout");
        if (path?.includes("investor")) {
          window.location.replace("/investor-login");
        } else if (path?.includes("broker")) {
          const brokerName = path.split("/")?.[2];
          window.location.replace(`/broker/${brokerName}/agent-login`);
        } else {
          window.location.replace("/login");
        }
      }
      return data;
    });
};
